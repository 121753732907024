import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './newshero.module.scss'

const NewsHero = ({ heroLine }) => (
  <StaticQuery
    query={graphql`
      query NewsHero {
        logo: file(relativePath: { eq: "top-left-hero.png" }) {
          childImageSharp {
            fixed(width: 530, height: 230) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hero: file(relativePath: { eq: "Motorway-Alt.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <div className={ styles.hero }>
        <div className={ styles.hero__offset }>
          <h1>{ heroLine }</h1>
        </div>
        <div className={ styles.hero__filter }></div>
        <Img
          className={ styles.hero__mobile_resize }
          fluid={ data.hero.childImageSharp.fluid }
        />
        <div className={ styles.hero__fixed_top_left }>
          <Link
            to="/"
            title="Return home"
          >
            <Img
              fixed={ data.logo.childImageSharp.fixed }
              alt="Motor Injury Help Line"
            />
          </Link>
        </div>
      </div>
    )}
  />
)

NewsHero.propTypes = {
  heroLine: PropTypes.string.isRequired,
}

export default NewsHero
